<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row justify="center" class="pt-5">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12">
              <v-switch v-model="selectedConfiguration.use_azure_openai" :label="$t('use_azure_openai')"></v-switch>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <v-select
                v-model="selectedConfiguration.model"
                :items="filteredModels"
                item-text="name"
                item-value="value"
                :label="$t('gpt_model')"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-select
                v-model="selectedConfiguration.language"
                :items="languages"
                item-text="name"
                item-value="value"
                :label="$t('select_main_language')"
                outlined
                dense
              ></v-select>
            </v-col>           
          </v-row>
          <v-row no-gutters>
            <v-col cols="8">
              <div>
                <div class="text-left header-highlight">
                  <label><strong>{{$t('options')}}</strong></label>
                </div>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-checkbox dense
                        v-model="selectedConfiguration.use_lang_detect"
                        hide-details="true"
                        :label="$t('use_lang_detect')">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :items="wordCountOptions"
                      :label="$t('detect_from')"
                      v-model="selectedConfiguration.lang_detect_word_count"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select :disabled="!selectedConfiguration.use_lang_detect"
                      v-model="selectedConfiguration.lang_detect_model"
                      :items="filteredModels"
                      item-text="name"
                      item-value="value"
                      :label="$t('gpt_model')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-checkbox dense
                      v-model="selectedConfiguration.use_input_history"
                      hide-details="true"
                      :label="$t('use_input_history')">
                    </v-checkbox>                    
                  </v-col>
                  <v-col cols="4">
                    <v-select :disabled="!selectedConfiguration.use_input_history"
                      :items="inputHistoryOptions"
                      :label="$t('number_history_inputs')"
                      v-model="selectedConfiguration.input_history_count"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-checkbox dense
                  v-model="selectedConfiguration.functions_enabled"
                  hide-details="true"
                  :label="$t('use_functions')">
                </v-checkbox>
                <v-checkbox dense
                  v-model="selectedConfiguration.rag_enabled"
                  hide-details="true"
                  :label="$t('use_rag')">
                </v-checkbox>
                <v-checkbox dense
                  v-model="selectedConfiguration.rag_products"
                  v-if="selectedConfiguration.rag_enabled"
                  hide-details="true"
                  :label="$t('use_rag_products')">
                </v-checkbox>
                <v-checkbox dense
                  v-model="selectedConfiguration.use_only_rag"
                  v-if="selectedConfiguration.rag_enabled"
                  hide-details="true"
                  :label="$t('use_only_rag')">
                </v-checkbox>
                <!-- <v-checkbox dense
                  v-model="selectedConfiguration.use_RAG_cache"
                  v-if="selectedConfiguration.rag_enabled"
                  hide-details="true"
                  :label="$t('use_rag_cache')">
                </v-checkbox> -->
                <v-row no-guttes>
                  <v-col cols="6">
                    <v-checkbox dense
                      v-model="selectedConfiguration.use_gpt35_url_search"
                      hide-details="true"
                      :label="$t('gpt35_url_search')">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-select :disabled="!selectedConfiguration.use_gpt35_url_search"
                      v-model="selectedConfiguration.search_url_model"
                      :items="filteredModels"
                      item-text="name"
                      item-value="value"
                      :label="$t('gpt_model')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>                
              </div>
            </v-col>            
            <v-col class="pl-3" cols="4">
                <div>
                <div class="text-left header-highlight">
                  <label><strong>{{$t('ai_parameters')}}</strong></label>
                </div>
                <v-subheader>{{$t('temperature')}}</v-subheader>
                <v-slider dense
                  hide-details="true"
                  v-model="selectedConfiguration.temperature"
                  max="1"
                  min="0"
                  step="0.1"
                  thumb-label
                ></v-slider>
                <v-subheader>TOP-P</v-subheader>
                  <v-slider dense
                  hide-details="true"
                  v-model="selectedConfiguration.topp"
                  max="1"
                  min="0"
                  step="0.1"
                  thumb-label
                ></v-slider>
                <div class="pb-4">
                  <h5>{{$t('max_token_output')}}</h5>
                  <input type="number"
                    v-model="selectedConfiguration.max_tokens"
                    min="500"
                    max="4096"
                    @change="selectedConfiguration.max_tokens = Math.min(Math.max(selectedConfiguration.max_tokens, 500), 4096)"
                  />
                  </div>
                </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
                <div class="text-left header-highlight">
                  <label><strong>{{$t('anonimize')}}</strong></label>
                </div>
                <v-checkbox dense
                    v-model="selectedConfiguration.anonimize_input"
                    hide-details="true"
                    :label="$t('use_anonimize_input')">
                </v-checkbox>
                <v-checkbox v-if="selectedConfiguration.anonimize_input" dense
                    v-model="selectedConfiguration.pseudonimize_input"
                    hide-details="true"
                    :label="$t('use_pseudonimize_input')">
                </v-checkbox>
                <v-row class="pt-5">
                  <v-col cols="11">
                    <v-select
                      v-model="selectedConfiguration.entitytypes_to_anonimize"
                      multiple
                      item-text="name"
                      item-value="value"
                      :items="entityTypeOptions"
                      :label="$t('select_entity_types')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>                 
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3">
                    <v-btn @click="openExceptionDialog"
                      color="secondary"
                      >{{$t('exceptions')}}
                    </v-btn>
                  </v-col>
                </v-row>
            </v-col>
            
            <v-col cols="4" class="pl-2">
              <div class="text-left header-highlight">
                <label><strong>{{$t('reporting')}}</strong></label>
              </div>
              <v-checkbox dense
                v-model="selectedConfiguration.use_reporting"
                hide-details="true"
                :label="$t('use_reporting')">
              </v-checkbox>
              <v-checkbox dense
                v-if="selectedConfiguration?.use_reporting"
                v-model="selectedConfiguration.use_labeling"
                hide-details="true"
                disabled
                :label="$t('use_labeling')">
              </v-checkbox>
              <div>
                <v-checkbox dense               
                  v-model="selectedConfiguration.use_post_processing"
                  hide-details="true"                
                  :label="$t('use_post_processing')">
                </v-checkbox>
                <v-select class="pt-2 pl-7" v-if="selectedConfiguration.use_post_processing"
                  v-model="selectedConfiguration.post_processing_method"                
                  item-text="name"
                  item-value="value"
                  :items="post_processing_methods"
                  :label="$t('send_via')"
                  outlined
                  dense
                ></v-select>
                <v-text-field class="pl-7" v-if="selectedConfiguration.use_post_processing"
                  v-model="selectedConfiguration.post_processing_recipient"
                  :label="$t('recipient')"
                  outlined
                  dense>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="4" class="pl-2">
              <div class="text-left header-highlight">
                <label><strong>{{$t('data')}}</strong></label>
              </div>
              <v-checkbox dense
                v-model="selectedConfiguration.include_manual_dataset"
                hide-details="true"
                :label="$t('include_manual_dataset')">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row justify="center" class="pb-3">
            <v-col cols="2">
              <v-btn @click="saveChanges"
                :disabled="!hasConfigurationChanges"
                color="primary"
                >{{$t('save')}}
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn @click="cancelChanges"
                :disabled="!hasConfigurationChanges"
                color="secondary"
                >{{$t('cancel')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="isExceptionsDialogOpen" persistent max-width="40vw">
      <v-card>
        <v-card-title class="justify-center grey primary white--text">
          {{$t('exceptions')}}
        </v-card-title>
        <v-card-text class="pa-4" style="max-height: 600px; overflow-y: auto;">
          <v-simple-table dense fixed-header height="300">
            <template v-slot:default>
              <thead>
                <tr>
                  <th><strong>{{$t('exception')}}</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(exception, index) in exceptions" :key="index">
                  <td>
                    <input type="text" v-model="exceptions[index]">
                  </td>
                  <td>
                    <v-btn class="pl-3" icon small @click="deleteException(index)">
                      <v-icon color="primary">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="isExceptionsDialogOpen = false">{{$t('close')}}</v-btn>
          <!-- Only show Add button for the last exception -->
          <v-btn class="pl-3" icon small @click="addException">
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
    import { EventBus } from '../../eventBus.js';
    export default {
        name: 'AiSettingsTab',
        data: () => ({
          rules: {
            numeric: value => !isNaN(parseFloat(value)) && isFinite(value) || 'Must be numeric.'
          },
          initialConfiguration: null,
          gptModels: [
            { name: 'GPT 3.5 Turbo', value: 'gpt-3.5-turbo' },
            { name: 'GPT 4o', value: 'gpt-4o' },
            { name: 'GPT 4o mini', value: 'gpt-4o-mini' },
            { name: 'GPT 4 Turbo',value: 'gpt-4-turbo' },
            { name: 'GPT 4', value: 'gpt-4' },
            { name: 'GPT 4 1106', value: 'gpt-4-1106-preview' },
            { name: 'GPT 4 Turbo Preview', value: 'gpt-4-turbo-preview'},
            { name: 'GPT 4 0125', value: 'gpt-4-0125-preview'}
          ],
          wordCountOptions: [3,4,5,6,7,8,9,10],
          inputHistoryOptions: [2,3,4,5],
          isExceptionsDialogOpen: false,
          exceptions: []
        }),
        computed: {
          selectedConfiguration: {
            get() {
              return this.$store.getters.selectedConfiguration;
            },
            set(value) {
              this.$store.dispatch('updateSelectedConfiguration', value);
            }
          },
          hasConfigurationChanges() {
            const result = JSON.stringify(this.selectedConfiguration) !== JSON.stringify(this.initialConfiguration);
            if (result) {
              EventBus.$emit('disable-tabs', true);
            }
            else {
              EventBus.$emit('disable-tabs', false);
            }
            return result;
          },
          filteredModels() {
            // If useAzureOpenAI is true, return only the specific model
            if (this.selectedConfiguration.use_azure_openai) {
              return [               
                { name: 'GPT 4o Default', value: 'gpt-4o-default' },
                { name: 'GPT 4o Mini Default', value: 'gpt-4o-mini-default' }
              ];
            }
            // Else return the full list
            return [
              { name: 'GPT 3.5 Turbo', value: 'gpt-3.5-turbo' },
              { name: 'GPT 4o', value: 'gpt-4o' },
              { name: 'GPT 4o mini', value: 'gpt-4o-mini' },
              { name: 'GPT 4 Turbo', value: 'gpt-4-turbo' },
              { name: 'GPT 4', value: 'gpt-4' },
              { name: 'GPT 4 1106', value: 'gpt-4-1106-preview' },
              { name: 'GPT 4 Turbo Preview', value: 'gpt-4-turbo-preview' },
              { name: 'GPT 4 0125', value: 'gpt-4-0125-preview' }
            ];
          },
          languages() {
            return [
              { name: this.$t('language.english'), value: 'en'},
              { name: this.$t('language.dutch'), value: 'nl'},
              { name: this.$t('language.german'), value: 'de'},
              { name: this.$t('language.french'), value: 'fr'},
              { name: this.$t('language.spanish'), value: 'es'},
              { name: this.$t('language.portuguese'), value: 'pt'},
            ];
          },
          entityTypeOptions() {
            return [
              { name: this.$t('person'), value: 'PER' },
              { name: this.$t('location'), value: 'LOC' },
              { name: this.$t('organization'), value: 'ORG' },
              { name: this.$t('gpe'), value: 'GPE' },
              { name: this.$t('date'), value: 'DATE' },
              { name: this.$t('time'), value: 'TIME' },
              { name: this.$t('money'), value: 'MONEY' },
              { name: this.$t('norp'), value: 'NORP' },
              { name: this.$t('email'), value: 'EMAIL' },
              { name: this.$t('phone'), value: 'PHONE' }
            ];
          }, 
          post_processing_methods() {
            return [
              { name: this.$t('sms'), value: 'sms' },
              { name: this.$t('email'), value: 'email' }
            ]
          }
        },
        watch: {
          selectedConfiguration: {
            handler(newValue) {
              if (newValue && newValue.use_reporting) {                
                this.selectedConfiguration.use_labeling = true;

                if (!this.selectedConfiguration.labeling_instruction) {
                  EventBus.$emit('show-snackbar', { type: 'WARN', message: this.$t('enter_labeling_instructions') });
                }
              } else if (newValue) {
                this.selectedConfiguration.use_labeling = false;
              }
            },
            deep: true // Ensures that nested properties are watched
          }
        },
        mounted() {
            this.initialize();
        },
        created() {
          // reinitialize upon configuration change
          EventBus.$on('set-configuration', () => {
            this.initialize();
          });
        },
        methods: {
          async initialize() {
            this.initialConfiguration = JSON.parse(JSON.stringify(this.selectedConfiguration));
          },
          cancelChanges() {
            if (this.hasConfigurationChanges) {
              this.selectedConfiguration = JSON.parse(JSON.stringify(this.initialConfiguration));
            }
          },
          async saveChanges() {
            if (this.hasConfigurationChanges) {
              EventBus.$emit('save-configuration', this.selectedConfiguration);
            }
          },
          deleteException(index) {
            this.exceptions.splice(index, 1);
          },
          addException() {
            this.exceptions.push('NEW');
            console.log('*** exceptions', this.exceptions);
          },
          openExceptionDialog() {
            this.exceptions = this.selectedConfiguration.exceptions || [];
            this.isExceptionsDialogOpen = true;
          },
          closeExceptionDialog() {
            this.selectedConfiguration.exceptions = this.exceptions;
          }
        }
    }

</script>

<style scoped>
.header-highlight {
  background-color: #f0f0f0; /* Light grey background color */
  padding: 10px; /* Padding around the header */
  border-radius: 5px; /* Optional: Rounded corners */
  margin-bottom: 10px; /* Optional: Space below the header */
}
.border-active {
  border: 2px solid #f0f0f0; /* Choose a color and style for your border */
  padding: 0px; /* Optional: add padding to make the border more visible */
  border-radius: 4px; /* Optional: add rounded corners if desired */
}
</style>

