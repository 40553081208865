<template>
    <v-container class="pt-5">
      <v-card flat>
        <v-row justify="center" class="pt-5">
          <v-col cols="12">
            <v-row>
              <v-col cols="10">
                <v-select
                  v-model="selectedFunction"
                  :items="selectedConfiguration.functions"
                  item-text="name"
                  return-object
                  :label="$t('functions')"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-btn @click="addFunction" icon small>
                  <v-icon color="black">mdi-plus</v-icon>
                </v-btn>
                <v-btn @click="deleteFunction" icon small
                  :disabled="!selectedFunction || !selectedFunction.name">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div v-if="selectedFunction.name">
              <v-text-field dense
                v-model="selectedFunction.name"
                outlined
                :label="$t('name')"
                @input="trimWhitespace"
              ></v-text-field>
              <v-textarea dense height="100"
                v-model="selectedFunction.description"
                outlined
                :label="$t('description')"
              ></v-textarea>
              <v-subheader>{{$t('parameters')}}</v-subheader>
                <v-simple-table dense fixed-header height="200" style="border: 1px solid black;">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>{{$t('name')}}</th>
                        <th>{{$t('description')}}</th>
                        <th>{{$t('prompt')}}</th>
                        <th>{{$t('required')}}</th>
                        <th>
                          <v-btn @click="addParameter" icon small>
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(paramDetails, paramName) in selectedFunction.parameters.properties" :key="paramName">
  
                        <!-- Parameter Name -->
                        <td @click.stop="editRow(paramName)">
                          <v-text-field
                            v-if="currentlyEditingRow === paramName"
                            v-model="tempNames[paramName]"
                            dense
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                          <span v-else>{{ paramName }}</span>
                        </td>
  
                        <!-- Description -->
                        <td @click.stop="editRow(paramName)">
                          <v-text-field
                            v-if="currentlyEditingRow === paramName"
                            v-model="paramDetails.description"
                            dense
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                          <span v-else>{{ paramDetails.description }}</span>
                        </td>
  
                        <!-- Prompt -->
                        <td @click.stop="editRow(paramName)">
                          <v-text-field
                            v-if="currentlyEditingRow === paramName"
                            v-model="selectedFunction.parameters.prompts[paramName]"
                            dense
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                          <span v-else>{{ selectedFunction.parameters.prompts[paramName] }}</span>
                        </td>
  
                        <!-- Required Checkbox & Control Buttons -->
                        <td>
                          <v-checkbox :input-value="isParamRequired(paramName)" disabled></v-checkbox>
                        </td>
                        <td>
                          <v-btn icon small @click.stop="removeParameter(paramName)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                          <v-btn icon small v-if="currentlyEditingRow === paramName" @click.stop="applyChanges">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </div>
            <v-row justify="center" class="pb-3">
              <v-col cols="2">
                <v-btn @click="saveChanges"
                  :disabled="!hasConfigurationChanges"
                  color="primary"
                  >{{$t('save')}}
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn @click="cancelChanges"
                  :disabled="!hasConfigurationChanges"
                  color="secondary"
                  >{{$t('cancel')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </template>
  
  <script>
      import { EventBus } from '../../eventBus.js';
      import  Vue from 'vue';
      export default {
          name: 'AifunctionsTabFunctions',
          data: () => ({
            initialConfiguration: null,
            selectedFunction: { parameters: { properties: {}, prompts: {}, required: [] } },
            currentlyEditingRow: null,
            tempNames: {},
          }),
          computed: {
            selectedConfiguration: {
              get() {
                return this.$store.getters.selectedConfiguration;
              },
              set(value) {
                this.$store.dispatch('updateSelectedConfiguration', value);
              }
            },
            hasConfigurationChanges() {
              const result = JSON.stringify(this.selectedConfiguration) !== JSON.stringify(this.initialConfiguration);
              if (result) {
                EventBus.$emit('disable-tabs', true);
              }
              else {
                EventBus.$emit('disable-tabs', false);
              }
              return result;
            },
          },
          mounted() {
              this.initialize();
          },
          created() {
            // reinitialize upon configuration change
            EventBus.$on('set-configuration', () => {
              this.initialize();
            });
          },
          methods: {
            async initialize() {
              this.initialConfiguration = JSON.parse(JSON.stringify(this.selectedConfiguration));
              this.selectedFunction = { parameters: { properties: {}, prompts: {}, required: [] } };
            },
            cancelChanges() {
              if (this.hasConfigurationChanges) {
                this.selectedConfiguration = JSON.parse(JSON.stringify(this.initialConfiguration));
                this.selectedFunction = {};
              }
            },
            async saveChanges() {
              if (this.hasConfigurationChanges) {
                EventBus.$emit('save-configuration', this.selectedConfiguration);
              }
            },
            isParamRequired(paramName) {
              return this.selectedFunction.parameters && this.selectedFunction.parameters.required.includes(paramName);
            },
            editRow(paramName) {
              this.currentlyEditingRow = paramName;
              this.tempNames = { ...this.tempNames, [paramName]: paramName };
            },
            applyChanges() {
              const paramName = this.currentlyEditingRow;
              const newName = this.tempNames[paramName];
              const paramDetails = this.selectedFunction.parameters.properties[paramName];
              const paramPrompt = this.selectedFunction.parameters.prompts[paramName];
  
              // Handle renaming if necessary
              if (newName && newName !== paramName) {
                this.handleRenaming(paramName, newName, paramDetails, paramPrompt);
              } else {
                // Update details without renaming
                this.selectedFunction.parameters.properties[paramName] = { ...paramDetails };
                this.selectedFunction.parameters.prompts[paramName] = paramPrompt;
              }
  
              this.currentlyEditingRow = null;
            },
            handleRenaming(oldName, newName, details, prompt) {
              this.$set(this.selectedFunction.parameters.properties, newName, details);
              this.$set(this.selectedFunction.parameters.prompts, newName, prompt);
  
              if (this.selectedFunction.parameters.required.includes(oldName)) {
                const requiredIndex = this.selectedFunction.parameters.required.indexOf(oldName);
                this.selectedFunction.parameters.required[requiredIndex] = newName;
              }
  
              this.$delete(this.selectedFunction.parameters.properties, oldName);
              this.$delete(this.selectedFunction.parameters.prompts, oldName);
            },  
            removeParameter(paramName) {
              Vue.delete(this.selectedFunction.parameters.properties, paramName);
              Vue.delete(this.selectedFunction.parameters.prompts, paramName);
              const requiredIndex = this.selectedFunction.parameters.required.indexOf(paramName);
              if (requiredIndex !== -1) {
                this.selectedFunction.parameters.required.splice(requiredIndex, 1);
              }
              if (this.currentlyEditingRow === paramName) this.currentlyEditingRow = null;
            },
            addFunction() {              
              if (!this.selectedConfiguration.functions) {               
                this.$set(this.selectedConfiguration, 'functions', []); // For Vue 2.x               
              }
              // Define a new, empty function object
              const newFunction = {
                name: 'New Function', // You might want to give a default name or handle naming differently
                description: '',
                parameters: {
                  properties: {},
                  prompts: {},
                  required: [],
                },
              };
              // Add the new function to the array
              this.selectedConfiguration.functions.push(newFunction);              
              this.selectedFunction = newFunction;
            },
            deleteFunction() {
              if (!this.selectedFunction || !this.selectedFunction.name) return; // Early exit if no function is selected or if it doesn't have a name
  
              const index = this.selectedConfiguration.functions.findIndex(f => f.name === this.selectedFunction.name);
              if (index > -1) {
                this.selectedConfiguration.functions.splice(index, 1);
                // Optionally reset selectedFunction or set it to another function
                if (this.selectedConfiguration.functions.length > 0) {
                  this.selectedFunction = this.selectedConfiguration.functions[0]; // Select the first function
                } else {
                  this.selectedFunction = {}; // Reset to default state if no other functions are left
                }
              }
            },
            addParameter() {
              const newParamName = `newParam_${Date.now()}`;
              this.$set(this.selectedFunction.parameters.properties, newParamName, { description: '', type: 'string' });
              this.$set(this.selectedFunction.parameters.prompts, newParamName, 'Default prompt');
              this.selectedFunction.parameters.required.push(newParamName);
            },
            trimWhitespace(event) {
              this.selectedFunction.name = event.target.value.trim();
            }
          }
      }
  </script>
  
  <style scoped>
  </style>
  
  