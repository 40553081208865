<template>
  <v-container>
    <v-row class="fill-height" justify="center">
      <v-col cols="auto">
        <v-card class="window-card pa-5" flat>
           <v-row no-gutters justify="center">
            <!-- Date From Picker -->
            <v-col cols="auto" >
              <v-menu
                ref="menuFrom"
                v-model="menuFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"                
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :label="$t('date_from')"
                    v-model="dateFrom"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="mdi-calendar"
                    outlined
                    dense       
                    hide-details="true"             
                  ></v-text-field>
                </template>
                <v-date-picker v-model="tempDateFrom" @input="setDateFrom($event)" :max="maxDate" no-title show-adjacent-months></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Date To Picker -->
            <v-col cols="auto" class="pl-3">
              <v-menu
                ref="menuTo"
                v-model="menuTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"                
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :label="$t('date_to')"
                    v-model="dateTo"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="tempDateTo" :max="maxDate" no-title @input="setDateTo($event)" show-adjacent-months></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Search Button -->
            <v-col class="pl-4" cols="auto">
              <v-btn fab
                  small color="primary" @click="searchConversations">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          
          <!-- <v-row class="text-center" >
            <v-col cols="auto">
              <h1 class="h5" v-if="conversationsByDate.length === 0">{{$t('no_conversations_found')}} {{ configurationName }}</h1>
            </v-col>
          </v-row> -->
          <v-row class="pt-4" v-if="selectedConfiguration?.use_reporting" no-gutters>
            <v-col
              v-for="(count, label) in labelCounts"
              :key="label"
              cols="auto"
            >
              <v-chip
                :class="{ active: activeLabel === label }"
                @click="toggleLabel(label)"
                class="ma-1"
                color="#1d0d3e"
                text-color="white"                
                :input-value="activeLabel === label"
              >
                <!-- Green checkmark if the label is active -->
                <v-icon left v-if="activeLabel === label" color="green">mdi-check</v-icon>

                <!-- Red cross if the label is not active -->
                <v-icon left v-else color="red">mdi-close</v-icon>

                {{ label }}: {{ count }}
              </v-chip>
            </v-col>
          </v-row>
          <div class="pt-3"></div>
          <v-data-table
            v-if="conversationsByDate.length > 0"
            class="scrollable-data-table"
            dense
            :items="conversationsByDate"
            item-key="date"
            show-group-by
            group-by="date"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            hide-default-footer
          >
            <template v-slot:[`group.header`]="{ items, isOpen, toggle }" >
              <tr @click="toggle" :style="{ 'background': '#fff !important' }">
                <td :colspan="headers.length">
                  <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                  <strong>
                    {{ items[0].date }} ({{ items[0].totalConversations }}
                    <span v-if="items[0].totalConversations > 1">{{$t('conversations')}}</span>
                    <span v-if="items[0].totalConversations === 1">{{$t('conversation')}}</span>)
                  </strong>
                </td>
              </tr>
            </template>
            <!-- Item Slot for Conversations -->
            <template v-slot:item="{ item }">
              <!-- Headers for Conversation Details -->
              <tr style="background-color: #EA6BB1; color: #fff;">
                <td class="center-text"><strong>{{$t('time')}}</strong></td>
                <td><strong>{{$t('number_of_messages')}}</strong></td>
                <td class="center-text"><strong>{{$t('conversation_type')}}</strong></td>
                <td v-if="selectedConfiguration?.use_reporting"><strong>{{$t('engagement')}}</strong></td>
                <td v-if="selectedConfiguration?.use_reporting"><strong>{{$t('satisfaction')}}</strong></td>
                <td v-if="selectedConfiguration?.use_reporting" class="center-text"><strong>{{$t('topics')}}</strong></td>
              </tr>
              <!-- Conversation Details -->
                <tr v-for="conversation in item.conversations" :key="conversation.id" @click="openDialog(conversation)">
                  <td>{{ formatDateTime(item.date, conversation.timeStarted) }}</td>
                  <!-- <td>{{item.date}} - {{ conversation.timeStarted }}</td> -->
                  <td class="center-text">{{conversation.user_messages_count}}</td>
                  <td>
                    <span v-if="conversation.type === 1" class="conversation-item">
                      <i class="mdi mdi-test-tube conversation-icon"></i> <span class="conversation-text">{{$t('test_conversation')}}</span>
                    </span>
                    <span v-if="conversation.type === 2" class="conversation-item">
                      <i class="mdi mdi-web conversation-icon"></i> <span class="conversation-text">{{$t('web_conversation')}}</span>
                    </span>
                    <span v-if="conversation.type === 5" class="conversation-item">
                      <i class="mdi mdi-whatsapp conversation-icon"></i> <span class="conversation-text">{{$t('whatsapp_conversation')}}</span>
                    </span>
                    <span v-if="conversation.type === 6" class="conversation-item">
                      <i class="mdi mdi-email conversation-icon"></i> <span class="conversation-text">{{$t('email_conversation')}}</span>
                    </span>
                  </td>
                  <td v-if="selectedConfiguration?.use_reporting" class="center-text">
                    <v-icon v-if="!conversation.engagement">mdi-timer-sand</v-icon>
                    <span v-else>{{ conversation.engagement.toFixed(2) }}</span>
                  </td>

                  <td v-if="selectedConfiguration?.use_reporting" class="center-text">
                    <v-icon v-if="!conversation.satisfaction">mdi-timer-sand</v-icon>
                    <v-icon v-else-if="conversation.satisfaction === 1">mdi-emoticon-frown-outline</v-icon>
                    <v-icon v-else-if="conversation.satisfaction === 2">mdi-emoticon-sad-outline</v-icon>
                    <v-icon v-else-if="conversation.satisfaction === 3">mdi-emoticon-neutral-outline</v-icon>
                    <v-icon v-else-if="conversation.satisfaction === 4">mdi-emoticon-happy-outline</v-icon>
                    <v-icon v-else-if="conversation.satisfaction === 5">mdi-emoticon-excited-outline</v-icon>
                  </td>

                  <td v-if="selectedConfiguration?.use_reporting" class="center-text">
                    <v-icon v-if="!conversation.labels || conversation.labels.length === 0">mdi-timer-sand</v-icon>
                    <v-chip
                      v-else
                      v-for="label in conversation.labels"
                      :key="label"
                      small
                      class="ma-1"
                    >
                      {{ label }}
                    </v-chip>
                  </td>
                </tr>


            </template>
          </v-data-table>
          <v-row no-gutters>
            <v-col cols="auto">
              <v-checkbox dense
                v-model="includeEmptyConversations"
                hide-details="true"
                :label="$t('include_empty_conversations')">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-btn v-if="isAdmin && conversationsByDate.length > 0" dense
                @click="deleteTestConversations"
              >
              Delete test conversations
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <detailed-conversation-dialog
      :dialog="detailedConversationDialog"
      :selected-conversation="selectedConversation"
      @close-dialog="detailedConversationDialog = false">
    </detailed-conversation-dialog>
    <conversation-dialog
      :dialog="simpleConversationDialog"
      :selected-conversation="selectedConversation"
      @close-dialog="simpleConversationDialog = false">
    </conversation-dialog>
  </v-container>
</template>


<script>
import ConversationDialog from './dialogs/ConversationDialog.vue';
import DetailedConversationDialog from './dialogs/DetailedConversationDialog.vue';
import { EventBus } from '../eventBus.js';
import axios from 'axios';

export default {
  name: 'ConversationsTab',
  components: {
    ConversationDialog,
    DetailedConversationDialog
  },
  props: {
    configurationId: String,
    currentProduct: String,
    configurationName: String
  },
  created() {
    EventBus.$on('closeCalendar', () => {
      this.menuFrom = false;
      this.menuTo = false;
    });
    EventBus.$on('clearConversations', () => {
      this.conversationsByDate = [];
      this.allConversationsByDate = [];
      this.searchConversations();
    });
  },

  data: () => ({
    menuFrom: false,
    menuTo: false,
    includeEmptyConversations: false,
    dateFrom: null, // Today's date in YYYY-MM-DD format
    tempDateFrom: null,
    dateTo: null, // Today's date in YYYY-MM-DD format
    tempDateTo: null,
    maxDate: null, // Today's date
    selectedConversation: null,
    simpleConversationDialog: false,
    detailedConversationDialog: false,
    expanded: [],
    headers: [
        { text: 'Date', value: 'date' },
        { text: 'Conversations', value: 'conversations' }
    ],
    conversationsByDate: [],
    allConversationsByDate: [],
    singleExpand: false,
    labelCounts: {},
    activeLabels: [],
    activeLabel: null
    
  }),
  mounted() {
    this.dateFrom = this.localDate;
    this.dateTo = this.localDate;
    this.maxDate = this.localDate;
    this.searchConversations();
  },
  computed: {
    isAdmin() {      
      return this.$store.state.userRole === 'admin';
    },
    localDate() {
      const date = new Date();
      const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
      return localDate.toISOString().substr(0, 10);
    },
    selectedConfiguration() {
      return this.$store.getters.selectedConfiguration;
    },
  },
  methods: {
    setDateFrom(newVal) {
      if (this.currentProduct === 'entry') {
        // Emit the event and reset the tempDateFrom
        EventBus.$emit('show-promosnackbar', {
          message: this.$t('promo.chatoverview_text'),
          header: this.$t('promo.chatoverview_header'),
          timeout: 10000
        });
        EventBus.$emit('closeCalendar');
        this.tempDateFrom = this.dateFrom; // Revert tempDateFrom to the original dateFrom
      } else {
        if (newVal > this.dateTo) {
          this.dateTo = newVal;
        }
        this.dateFrom = newVal; // Update dateFrom to the new value
      }
    },
    setDateTo(newVal) {
      if (this.currentProduct === 'entry') {
        // Emit the event and reset the tempDateTo
        EventBus.$emit('show-promosnackbar', {
          message: this.$t('promo.chatoverview_text'),
          header: this.$t('promo.chatoverview_header'),
          timeout: 10000
        });
        EventBus.$emit('closeCalendar');
        this.tempDateTo = this.dateTo; // Revert tempDateTo to the original dateTo
      } else {
        if (newVal < this.dateFrom) {
          this.dateFrom = newVal;
        }
        this.dateTo = newVal; // Update dateTo to the new value
      }
    },
    async searchConversations() {
      EventBus.$emit('show-overlay');
      try {
        const token = localStorage.getItem('userToken');
        const params = new URLSearchParams({
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          configurationId: this.configurationId,
          includeEmptyConversations: this.includeEmptyConversations
        }).toString();

        const response = await axios.get(`${this.$config.configurationServer}/chatoverview/list_sessions_for_customer?${params}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.conversationsByDate = response.data;
        this.allConversationsByDate = response.data;
        this.extractLabels();

      } catch (error) {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });

        if (error.response && error.response.data.error === "E100") {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('too_many_search_results') });
        } else {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('unknown_error')});
        }
      } finally {
        EventBus.$emit('hide-overlay');
      }
    },
    extractLabels() {
      const labelCounts = {};
      this.allConversationsByDate.forEach(dateItem => {
        dateItem.conversations.forEach(conversation => {
          if (conversation.labels && Array.isArray(conversation.labels)) {
            conversation.labels.forEach(label => {
              if (labelCounts[label]) {
                labelCounts[label]++;
              } else {
                labelCounts[label] = 1;
              }
            });
          }
        });
      });
      this.labelCounts = labelCounts;
      // console.log('*** labelCounts', this.labelCounts);
    },
    toggleLabel(label) {
      // If the clicked label is already active, deactivate it
      if (this.activeLabel === label) {
        this.activeLabel = null; // Deselect if already active
      } else {
        // Set the clicked label as the active one
        this.activeLabel = label; // Only one active label at a time
      }
      
      // Call your filterConversations method after updating the active label
      this.filterConversations();
    },
    async filterConversations() {
      if (!this.activeLabel) {
        // No active label, show all conversations
        await this.searchConversations(); // Assuming this shows all conversations
      } else {
        // Create a subset of the original conversations by filtering them
        const filteredConversations = this.allConversationsByDate.map(dateItem => {
          return {
            ...dateItem,
            conversations: dateItem.conversations.filter(conversation => {
              // Check if any conversation has the active label
              return conversation.labels.includes(this.activeLabel);
            })
          };
        });       

        // Optionally, update the state with the filtered data
        this.conversationsByDate = filteredConversations;
      }
    },

    async deleteTestConversations() {
      EventBus.$emit('show-overlay');
      try {
        const token = localStorage.getItem('userToken');
        const body = {
          configuration_id: this.configurationId
        };

        await axios.post(`${this.$config.configurationServer}/chatoverview/delete_test_sessions`, body, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('delete_successful') });
      } catch (error) {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });

        if (error.response && error.response.data.error) {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t(error.response.data.error) });
        } else {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('unknown_error')});
        }
      } finally {
        EventBus.$emit('hide-overlay');
      }
    },
    async openDialog(conversation) {
      EventBus.$emit('show-overlay');
      try {
        this.selectedConversation = this.isAdmin ? await this.getSession(conversation) : await this.getSessionForCustomer(conversation);
        if (this.isAdmin) {
          this.detailedConversationDialog = true;
        } else {
          this.simpleConversationDialog = true;
        }

      } catch (error) {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });
      } finally {
        EventBus.$emit('hide-overlay');
      }
    },

    async getSessionForCustomer(conversation) {
      const token = localStorage.getItem('userToken');
      const params = new URLSearchParams({
        sessionId: conversation.id
      }).toString();

      const response = await axios.get(`${this.$config.configurationServer}/chatoverview/get_session_for_customer?${params}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    },

    async getSession(conversation) {
      const token = localStorage.getItem('userToken');
      const params = new URLSearchParams({
        session_id: conversation.id
      }).toString();

      const response = await axios.get(`${this.$config.configurationServer}/chatoverview/get_session?${params}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    },

    formatDateTime(date, time) {
      // Combine the date and time into a single string
      const dateTime = new Date(`${date}T${time}Z`); // Add 'Z' to indicate UTC time

      // Format the dateTime to the user's local timezone without seconds
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short' // Optionally include the timezone abbreviation
      }).format(dateTime);
    },

    closeDialog() {
      this.dialog = false;
    },
    collapseAllGroups() {
      // Since there's no direct method to collapse all groups,
      // you may need to track the state of all groups and toggle them as needed.
      // This is a workaround and the actual implementation may vary.
      this.expanded = []; // Reset the expanded array to collapse all groups
      EventBus.$emit('show-promosnackbar', { message: this.$t('promo_chatoverview_text'), header: this.$t('promo_chatoverview_header') });
    },
    generateStarRating(rating) {
      const fullStar = '★';
      const emptyStar = '☆';
      let stars = '';

      for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
          stars += fullStar;
        } else {
          stars += emptyStar;
        }
      }

      return stars;
    }
  }
}
</script>

<style scoped>
.styled-table thead tr {
  background-color: #1d0d3e; /* Dark shade */
}

.styled-table thead tr th {
  color: white !important; /* Light text color for contrast */
  text-align: center !important; /* Center-align header titles */
}

.window-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.12);
}

.scrollable-data-table {
  height: 400px; /* Adjust this value to your needs */
  overflow-y: auto;
  border: 1px solid lightgrey;
}

.conversation-icon {
      font-size: 24px; /* Increase the icon size */
      margin-right: 8px; /* Add some space between the icon and text */
      vertical-align: middle; /* Align icon with text */
}
.conversation-text {
  font-size: 14px; /* Increase the text size */
}

.v-chip.active {
  background-color: #000000; /* Darker shade */
  border: 2px solid #363636; /* Thicker border */
  color: rgb(0, 0, 0);
}
.v-chip {
  border-radius: 25px;
}

.center-text {
  text-align: center;
}

/* Smaller devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .scrollable-data-table .v-data-table__wrapper {
    max-height: 300px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .scrollable-data-table .v-data-table__wrapper {
    max-height: 350px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  .scrollable-data-table .v-data-table__wrapper {
    max-height: 400px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
  .scrollable-data-table .v-data-table__wrapper {
    max-height: 500px;
  }
}

</style>
