<template>
  <v-container class="pt-5">
    <v-row no-gutters justify="center">
      <v-col cols="12" >
        <v-card>
          <v-toolbar dense dark flat color="primary" v-if="selectedConfiguration">
            <template>
              <v-tabs v-model="tab" background-color="transparent">
                <v-tab :disabled="tabsDisabled">{{$t('general')}}</v-tab>
                <v-tab :disabled="!selectedConfiguration._id || tabsDisabled">{{$t('ai_settings')}}</v-tab>
                <v-tab :disabled="!selectedConfiguration._id || tabsDisabled">{{$t('ai_functions')}}</v-tab>
                <v-tab :disabled="!selectedConfiguration._id || tabsDisabled">{{$t('instructions')}}</v-tab>
                <v-tab :disabled="!selectedConfiguration._id || tabsDisabled">{{$t('connections')}}</v-tab>
                <v-tab :disabled="!selectedConfiguration._id || tabsDisabled">{{$t('data_training')}}</v-tab>
                <v-tab :disabled="!selectedConfiguration._id || tabsDisabled">{{$t('chat_design')}}</v-tab>
                <v-tab :disabled="!selectedConfiguration._id || tabsDisabled">{{$t('ai_test')}}</v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="tab" v-if="selectedConfiguration">
            <!-- General Tab -->
            <v-tab-item>
              <GeneralTab/>
            </v-tab-item>
            <!-- AI Settings Tab -->
            <v-tab-item>
              <AiSettingsTab/>
            </v-tab-item>
            <!-- AI Functions tab -->
            <v-tab-item class="functions-tab-content">
              <AiFunctionsTab/>
            </v-tab-item>
            <v-tab-item>
              <AiInstructionsTab/>
            </v-tab-item>
            <v-tab-item>
              <ConnectionsTab/>
            </v-tab-item>
            <v-tab-item>
              <DataTrainingTab/>
            </v-tab-item>
            <v-tab-item>
              <ChatDesignTab/>
            </v-tab-item>
            <v-tab-item>
              <ChatTestTab/>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from '../eventBus.js';
import GeneralTab from './configurationTabs/GeneralTab.vue'
import AiSettingsTab from './configurationTabs/aiSettingsTab.vue'
import AiFunctionsTab from './configurationTabs/aiFunctionsTab.vue'
import AiInstructionsTab from './configurationTabs/aiInstructionsTab.vue'
import ConnectionsTab from './configurationTabs/connectionsTab.vue'
import DataTrainingTab from './configurationTabs/dataTrainingTab.vue'
import ChatDesignTab from './configurationTabs/chatDesignTab.vue'
import ChatTestTab from './configurationTabs/chatTestTab.vue'
import axios from 'axios';
  export default {
    name: 'ConfigurationsTab',

    components: {
      GeneralTab,
      AiSettingsTab,
      AiFunctionsTab,
      AiInstructionsTab,
      ConnectionsTab,
      DataTrainingTab,
      ChatDesignTab,
      ChatTestTab
    },

    data: () => ({
        rules: {
          numeric: value => !isNaN(parseFloat(value)) && isFinite(value) || 'Must be numeric.'
        },
        tab: null,
        instructionsTab: null,
        connectionsTab: null,
        configurations: [],
        selectedConfigurationId: null,
        selectedSubscription: {},
        configurationName: '',
        customers: [],
        selectedCustomer: null,
        gptModels: [
            { name: 'GPT 3.5 Turbo', value: 'gpt-3.5-turbo' },
            { name: 'GPT 4', value: 'gpt-4' },
            { name: 'GPT 4 Turbo', value: 'gpt-4-1106-preview' }
        ],
        subscriptions: [
          { name: 'Entry', value: 'entry' },
          { name: 'Basic', value: 'basic' },
          { name: 'Premium', value: 'premium' },
          { name: 'Business', value: 'business' },
          { name: 'Professional', value: 'professional' }
        ],
        selectedGptModel: null,
        temperature: 0,
        topP: 0,
        maxTokens: 500,
        userEmail: '',
        consentUrl: '',
        menu: false,
        selectedFunction: {},
        emailChannelConfiguration: {
          selectedOption: ''
        },
        emailOptions: [
          { text: 'USE_IMAP', value: 'use_imap' },
          { text: 'USE_OUTLOOK_CONSENT', value: 'use_outlook_consent' },
        ],
        twilioChannelConfiguration: {

        },
        tabsDisabled: false
    }),
    computed: {
      selectedConfiguration() {
        return this.$store.getters.selectedConfiguration;
      },
    },
    mounted() {
        this.initializeConfigurationTab();
    },
    created() {
      EventBus.$on('disable-tabs', (value) => {
        this.tabsDisabled = value;
      });
      EventBus.$on('load-configuration', (configurationId) => {
        this.loadConfiguration(configurationId);
        this.loadConfigurations();
      });
      EventBus.$on('load-configurations', () => {
        this.loadConfigurations();
      });
    },
    methods: {
        async initializeConfigurationTab() {
          EventBus.$emit('show-overlay');
          try {
              await Promise.all([
                  this.loadConfigurations(),
                  this.loadCustomers()
              ]);
          } catch (error) {
              console.error("Error during initialization:", error);
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('initialization_error') });
          } finally {
              EventBus.$emit('hide-overlay');
          }
        },
        async loadConfigurations() {
          const configs = await this.getConfigurations();
          this.configurations = configs.map(c => ({ text: c.name, value: c._id }));
        },
        async loadCustomers() {
          this.customers = await this.getCustomers();
        },
        async loadConfiguration(configurationId) {
            const fullConfiguration = await this.getConfiguration(configurationId);
            if (fullConfiguration) {
                this.$store.dispatch('updateSelectedConfiguration', fullConfiguration);
                EventBus.$emit('set-configuration', fullConfiguration);
                EventBus.$emit('clearConversations');
            } else {
                console.warn('Failed to load configuration or configuration not found.');
            }
        },
        async createNewConfiguration() {
          // create empty configuration
          const configuration = {
            name: 'New configuration',
            "functions": [{}],
            "entitytypes_to_anonimize": [],
            "max_tokens": 1500,
          }
          EventBus.$emit('save-configuration', configuration);
        },
        getCustomers() {
          const token = localStorage.getItem('userToken');
            if (token) {
                return axios.get(this.$config.configurationServer + '/customers', {
                  headers: { Authorization: `Bearer ${token}` }
                })
                .then(configResponse => {
                if (configResponse.status === 200) {
                    return configResponse.data;
                } else {
                    EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('data_retrieval_unsucces') });
                    return [];
                }
                })
                .catch(error => {
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('data_retrieval_unsucces') + error });
                  return [];
                });
            } else {
                return Promise.resolve([]);
            }
        },
        getConfigurations() {
          const token = localStorage.getItem('userToken');
          if (token) {
              return axios.get(this.$config.configurationServer + '/configurations', {
              headers: { Authorization: `Bearer ${token}` }
              })
              .then(configResponse => {
              if (configResponse.status === 200) {
                  return configResponse.data;
              } else {
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') });
                  return [];
              }
              })
              .catch(error => {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') + error });
              return [];
              });
          } else {
              return Promise.resolve([]);
          }
        },
        getConfiguration(configurationId) {
            const token = localStorage.getItem('userToken');
            if (token) {
                EventBus.$emit('show-overlay');

                const params = new URLSearchParams({
                  configuration_id: configurationId
                }).toString();

                return axios.get(`${this.$config.configurationServer}/configuration?${params}`, {
                headers: { Authorization: `Bearer ${token}` }
                })
                .then(configResponse => {
                if (configResponse.status === 200) {
                    return configResponse.data;
                } else {
                    EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') });
                    return null;
                }
                })
                .catch(error => {
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') + error });
                  return null;
                })
                .finally(() => {
                  this.getSubscription(configurationId, token);
                  EventBus.$emit('hide-overlay');
                });
            } else {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('login_required') });
                return Promise.resolve(null);
            }
        },
        getSubscription(configurationId, token) {
          const params = new URLSearchParams({
                configuration_id: configurationId
                }).toString();

          axios.get(`${this.$config.configurationServer}/orders/get-subscription?${params}`, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(response => {
            if (response.status === 200) {
              this.selectedSubscription = response.data;
            } else {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('subscription_unsucces') });
            }
          })
          .catch(error => {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('subscription_unsucces') + error });
            return null;
          })
        },
        setSubscription() {
          const token = localStorage.getItem('userToken');
          const configurationId = this.selectedSubscription.configuration_id;
          axios.post(`${this.$config.configurationServer}/orders/update-subscription`, this.selectedSubscription, {
              headers: { Authorization: `Bearer ${token}` },
              params: { configuration_id: configurationId },
              // Customize which HTTP status codes should resolve the promise
              validateStatus: function (status) {
                  return (status >= 200 && status < 300) || status === 304; // Resolve only if status code is successful or 304
              }
          })
          .then(response => {
              if (response.status === 200) {
                  EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('subscription_update_success') });
              } else if (response.status === 304 || response.status === 204) { // Document not updated
                  EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('no_updates_were_made') });
              } else {
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('subscription_update_fail') });
              }
          })
          .catch(error => {
              console.log('*** error: ', error);
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('subscription_update_fail') + error });
          });
        },
        generateConsentLink() {
          const clientId = '174d3e4a-ad65-4d06-a74e-f73ccbbae606';
          const configurationServer = this.$config.configurationServer;
          const redirectUri = encodeURIComponent(`${configurationServer}/consents/email`);
          const scopes = [
            'https://graph.microsoft.com/Mail.ReadWrite',
            'https://graph.microsoft.com/Mail.Send'
          ];
          const scope = encodeURIComponent(scopes.join(' '));
          const tenant = 'common'; // Use 'common' for multi-tenant apps
          const configurationId = this.selectedConfiguration._id;
          const state = encodeURIComponent(configurationId);
          this.consentUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&response_mode=query`;
        },
        formatAmount() {
        if (this.selectedSubscription.manual_amount != null) {
          const amount = parseFloat(this.selectedSubscription.manual_amount);
          if (!isNaN(amount)) {
            this.selectedSubscription.manual_amount = amount.toFixed(2);
          }
        }
      },
      /// TEMP METHODS FOR TESTING ONLY
      triggerPaymentWebhook() {
        const token = localStorage.getItem('userToken');
        const paymentId = 'tr_wwdVhUSj8V'; // Ensure you have a valid payment ID to test

        // Create FormData and append the paymentId with the key expected by the backend ('id')
        let formData = new FormData();
        formData.append('id', paymentId);

        axios.post(`${this.$config.configurationServer}/orders/payments_webhook`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data' // Important for FormData
          },
          // Customize which HTTP status codes should resolve the promise
          validateStatus: function (status) {
              return (status >= 200 && status < 300) || status === 304; // Resolve only if status code is successful or 304
          }
        })
        .then(response => {
          console.log(response.data); // Handle success
        })
        .catch(error => {
          console.error('Error triggering payment webhook:', error); // Handle error
        });
      }
    }
  }
</script>


<style scoped>
.functions-tab-content {
   max-height: 60vh;
   overflow-y: auto;
   overflow-x:hidden;
}

@media (max-width: 600px) { /* Example for small screens */
  .functions-tab-content {
    max-height: 60vh; /* You might want a different height for smaller screens */
  }
}
</style>
